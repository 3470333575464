<template>
  <ftx-dialog
    :dialog="dialog"
    position="right"
    maximized
    :onHide="onReset"
    :beforeShow="beforeShow"
    :persistent="true"
  >
    <template v-slot:DialogContent>
      <q-card class="addAddressDialogue">
        <q-card-section>
          <q-form @submit="handleAddressAdd" ref="addressForm" @reset="onReset">
            <div class="topHeader">
              <h3>
                <q-btn v-if="isMobile" flat dense @click="closeDialog">
                  <SvgIcon
                    className="q-mr-xs"
                    size="1rem"
                    icon="back-arrow-line"
                  />
                </q-btn>
                <template
                  v-if="
                    properties.address && properties.address.customerAddressesID
                  "
                >
                  Edit address
                </template>
                <template v-else>Add new address </template>
              </h3>
              <q-btn
                v-if="!isMobile"
                unelevated
                flat
                round
                icon="close"
                @click="closeDialog"
                class="close"
              />
            </div>
            <div class="middle">
              <q-scroll-area
                class="addAddressHeight"
                :thumb-style="scrollAreaThumbStyle"
                :bar-style="scrollAreaBarStyle"
                :visible="scrollAreaVisible"
                :style="[{ maxWidth: '100%' }]"
                ref="addAddressScrollArea"
              >
                <div class="row q-col-gutter-lg">
                  <div class="col-xs-12 col-md-12">
                    <AddressField
                      :addressFieldChange="addressFieldChange"
                      :resetForm="onReset"
                      :addressObj="addressObj"
                      :cordinates="coordinates"
                    />
                  </div>
                </div>
              </q-scroll-area>
            </div>
            <div class="bottom">
              <q-btn
                unelevated
                no-caps
                :rounded="!isMobile"
                outline
                color="tertiary"
                label="Cancel"
                @click="closeDialog"
                class="q-ml-sm q-mr-sm q-px-md q-py-xs"
              />
              <q-btn
                unelevated
                no-caps
                :rounded="!isMobile"
                color="secondaryOnBody"
                label="Save"
                class="q-ml-sm q-mr-sm q-px-md q-py-xs"
                type="submit"
                :loading="addingAddress"
              />
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import AddressField from 'components/address/AddressField'
import { mapGetters, mapActions } from 'vuex'

function initialState() {
  return {
    addingAddress: false,
    dialog: 'addShippingAddress',
    addressObj: {
      firstName: '',
      lastName: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      phone: '',
      securityCode: '',
      instruction: '',
      markAsDefault: false,
      geoLocation: '',
      storeGeoLocation: '',
      fulfillmentRadius: 0,
    },
    isChangesUnsaved: false,
    coordinates: null,
    resetAddressWatcher: () => {},
  }
}
export default {
  name: 'AddAddressDialog',
  props: {
    address: {
      type: Object,
      default: null,
    },
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  components: { AddressField },
  data: function () {
    return initialState()
  },
  methods: {
    ...mapActions('location', ['getAvailableNearByStoreDetails']),
    async handleAddressAdd() {
      let reqData = { ...this.addressObj }
      if (!reqData.geoLocation) {
        this.$store.commit('address/SET_ADDRESS_ALERT', {
          alertKey: 'addAddressAlert',
          alertData: {
            variant: 'danger',
            message:
              'Unable to validate address. When typing your address, please select the Google result that matches.',
          },
        })
        this.scrollToPosition(this.$refs.addAddressScrollArea, 0, 100)
        return
      }
      this.addingAddress = true

      if (reqData.phone)
        reqData.phone = this.unmask(reqData.phone, this.phoneMask)
      if (this.properties.customerID)
        reqData.customerID = this.properties.customerID

      let data = await this.$store.dispatch(
        'address/addOrUpdateAddress',
        reqData
      )

      this.addingAddress = false

      if (data) {
        if (this.$root) {
          this.$root.$emit('address-added-or-modified', {
            ...data,
            fulfillmentId: this.addressObj.fulfillmentId || '',
          })
        }
        this.dialogState = false

        let message =
          this.properties.address && this.properties.address.customerAddressesID
            ? 'Address modified successfully'
            : 'Address added successfully'

        this.showSuccess(message)
      }
    },
    onReset() {
      this.resetAddressWatcher()
      Object.assign(this.$data, initialState())
      if (this.$refs.addressForm && this.$refs.addressForm.resetValidation)
        this.$refs.addressForm.resetValidation()

      setTimeout(() => {
        this.isChangesUnsaved = false
      }, 200)
    },
    async beforeShow() {
      if (this.properties.address) {
        Object.assign(this.$data.addressObj, this.properties.address)
        this.addressObj.markAsDefault =
          this.properties.address.isDefault || false
        if (this.properties.address.geoLocation) {
          const result = await this.getAvailableNearByStoreDetails({
            geoLocation: this.properties.address.geoLocation,
            currentStorelocationID: this.currentLocationId,
          })

          if (result.data) {
            this.coordinates = result.data.map((item) => {
              const lat = parseFloat(item.geoLocation.split(',')[0])
              const lng = parseFloat(item.geoLocation.split(',')[1])

              return {
                locationName: item.locationName,
                geoLocation: { lat, lng },
              }
            })
          }
        }
        /* Start: Set Watcher for address */
        this.resetAddressWatcher = this.$watch('addressObj', watcherCallback, {
          deep: true,
        })
        function watcherCallback(newVal) {
          if (newVal) this.isChangesUnsaved = true
        }
        /* End: Set Watcher for address */
      }
    },
    addressFieldChange(field, val) {
      this.addressObj[field] = val
    },
    openConfirmationPopup() {
      const confirmationDialogTitle = 'Save Before Closing ?'
      const confirmationDialogDescription = `It looks like you're trying to close the tab before saving your changes.<br />Would you like to save or discard these changes?`
      const cancelBtnLabel = 'Discard and Close'
      const confirmBtnLabel = 'Save and Close'
      const closeBtn = false
      const outsideClickCloseDisable = true

      this.ftxConfirm(
        confirmationDialogTitle,
        confirmationDialogDescription,
        cancelBtnLabel,
        confirmBtnLabel,
        closeBtn,
        outsideClickCloseDisable
      )
        .onOk(() => {
          this.$refs.addressForm.validate().then((success) => {
            if (success) this.handleAddressAdd()
          })
        })
        .onCancel(() => {
          this.dialogState = false
        })
    },
    closeDialog() {
      if (!this.isChangesUnsaved) this.dialogState = false
      else this.openConfirmationPopup()
    },
  },
}
</script>
<style lang="scss">
.addAddressDialogue {
  width: 100vw !important;
  @media (min-width: 481px) {
    width: 440px !important;
  }
  @media (min-width: 1024px) {
    width: 860px !important;
  }
  .form-field,
  .form-action {
    padding-bottom: 16px;
    .q-field--with-bottom {
      padding-bottom: 0px;
    }
    @media (max-width: 767px) {
      .q-field__bottom {
        position: relative;
        padding: 0;
        margin-bottom: 5px;
      }
    }
  }

  .q-card__section {
    padding: 0;
  }
  .topHeader {
    padding: 20px 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .back {
      .q-focus-helper {
        display: none;
      }
      .q-btn__wrapper {
        padding: 0 0 4px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .middle {
    padding: 30px 30px;
    @media (max-width: 767px) {
      padding: 15px 15px;
    }
    .addAddressHeight {
      padding-right: 20px;
      height: calc(100vh - 211px);
      @media (max-width: 1023px) {
        height: calc(100vh - 170px) !important;
      }
    }
  }
  .bottom {
    padding: 10px 30px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 1023px) {
      padding: 5px 15px;
    }
    .q-btn {
      width: 180px;
      margin: 7px 0;
      padding: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        padding: 5px 8px;
      }
    }
    .q-btn:last-child {
      float: right;
    }
  }
}
</style>
